import React, {createContext, useReducer} from 'react'

export const CartContext = createContext();

const initialState = {
    products: [],
};

const reducer = (state, action) => {
    return{...state, ...action}
};

export const CartContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <CartContext.Provider value={{state, dispatch}}>
            {props.children}
        </CartContext.Provider>
    )
};