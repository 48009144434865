import React, {createContext, useReducer} from 'react'

export const AuthContext = createContext();

const initialState = {
    currentUser: null,
    authToken: null,
    authUrl: null,
    authPackage: null,
    storeType: null
};

const reducer = (state, action) => {
    return{...state, ...action}
};

export const AuthContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AuthContext.Provider value={{state, dispatch}}>
            {props.children}
        </AuthContext.Provider>
    )
};