import React, {createContext, useReducer} from 'react'

export const AppContext = createContext();

const initialState = {
    allStores: null,
    allSortedProductsByCategory: null,
    allSortedServicesByCategory: null,

    currentStoreData: null,
    storeType: null,
    categoryToDisplayInStore: null,

    selectedProductCategory: "Nekategorisano",
    selectedCity: null,

    isLoading: false,
    allStoresCache:{}
};

const reducer = (state, action) => {
    return {...state, ...action}
};

export const AppContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {props.children}
        </AppContext.Provider>
    )
};