import React, {useEffect} from 'react';
import {useLocation} from "react-router";
import {environment} from "../utils/config";

const UseRedirectToHttps = () => {
    let location = useLocation()
    useEffect(() => {
        if (window.location.protocol !== "https:" && environment === 'production') {
            window.location.replace("https://tcsrbija.com" + location.pathname);
        }
    }, [])
};

export default UseRedirectToHttps;