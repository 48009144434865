//TODO: render a loading gif
import axios from "axios";
import {serverUrl} from "./config";
import firebase from 'firebase/app';
import React from "react";
import {Helmet} from "react-helmet";

export async function getAllStores() {
    return new Promise(async (resolve, reject) => {
        await axios({
            method: "POST",
            url: serverUrl + "/getAllStores",
            data: [],
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.status === 200) {
                    let allStoresData = response.data.allStores
                    resolve(allStoresData)
                } else {
                    reject("error")
                }
            }
        ).catch(e => {
            reject("error")
        })
    })

}

export function getProductFromTheCart(productDetails, products) {

    let productsThatMatch = products.filter(function (productInTheCart) {
        return productDetails.sku === productInTheCart.sku
    })
    if (productsThatMatch.length > 1) {
        // "Error adding a product")
        new Error("Error in the cart!")
    } else if (productsThatMatch.length === 1) {
        return productsThatMatch[0]
    } else {
        return null
    }
}

export async function callServer(routeToCall, arrayOfData) {
    // Array.isArray(arrayOfData))
    // arrayOfData)
    if (Array.isArray(arrayOfData)) {
        let formData = new FormData()
        arrayOfData.forEach(item => {
            formData.append(item.name, item)
        })
        // routeToCall)
        return new Promise(async (resolve, reject) => {
            await axios({
                method: "POST",
                url: serverUrl + routeToCall,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(response => {
                    if (response.status === 200) {
                        // "Success, server responded")
                        resolve(response)
                    } else {
                        // "Error occurred")
                        reject("Error fetching")
                    }
                }
            ).catch(e => {
                // e)
            })
        })
    } else {
        throw Error("Pass in the array of data objects like {name: 'blah', data: {object}")
    }
}

export async function callServerAlt(routeToCall, arrayOfData) {
    // the arrayOfData is expected in the following form [{name: 'storeUrl', data: storeUrl}]
    let formData = new FormData()
    arrayOfData.forEach(item => {
        formData.append(item.name, JSON.stringify(item.data))
    })
    // "calling: " + routeToCall)
    let response = await axios({
        method: "POST",
        url: serverUrl + routeToCall,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
    if (response.status === 200) {
        // "Success, server responded")
        return Promise.resolve(response)
    } else {
        // "Error occurred")
    }
}

export async function fetchRedirectUrlAndType(currentUser, authToken) {
    return new Promise(async (res, rej) => {
        try {
            let response = await callServerAlt(
                '/getApplicableStoreForAdmin',
                [
                    {name: 'userEmail', data: currentUser.email},
                    {name: 'authToken', data: authToken}
                ]
            )
            res({storeUrl: response.data.storeUrl, storeType: response.data.storeType})
        } catch (e) {
            rej(e)
        }
    })
}

export function customLog(string, variable) {
    // string + ": ", variable)
}

export function handleSignOut() {
    // "singing out...")
    firebase.auth().signOut()
    window.location.reload()
}

export function getAllCategories(allSortedProductsByCategory) {
    let categories = []
    allSortedProductsByCategory.forEach(category => {
        categories.push(category.categoryName)
    })
    return categories
}

export function injectSchema(schema) {
    return (
        <script
            // key={`jobJSON-${}`}
            type='application/ld+json'
            dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
        />
    )

}

export function makeCompanySchema(company) {
    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": company.city + ", Serbia",
            // "postalCode": "F-75002",
            "streetAddress": company.address
        },
        "email": "",
        "name": company.storeName,
        "telephone": company.phone
    }
}

export function injectMetaTag(title, description, keywords, imageUrl, logoUrl, url) {
    let metaElements = [];

    if (title) {
        metaElements.push(
            <title key="title">{title}</title>,
            <meta key="og:site_name" property="og:site_name" content={title}/>,
            <meta key="twitter:site" property="twitter:site" content={title}/>,
            <meta property="og:url" content={"https://www.tcsrbija.com/" + url}/>,
            <meta property="og:type" content="website"/>
        );
    }

    if (description) {
        metaElements.push(
            <meta key="description" name="description" content={description}/>,
            <meta key="og:description" property="og:description" content={description}/>,
            <meta key="twitter:description" property="twitter:description" content={description}/>,
        );
    }

    if (keywords) {
        metaElements.push(
            <meta key="keywords" name="keywords" content={keywords}/>,
        );
    }

    if (imageUrl) {
        metaElements.push(
            <meta key="og:image" name="og:image" content={imageUrl}/>,
            <meta property="og:image" content={imageUrl}/>,
            <meta key="twitter:image" name="twitter:image" content={imageUrl}/>,
        );
    }
    if (logoUrl) {
        metaElements.push(<link rel="icon" href={logoUrl}/>)
    }
    return <Helmet>{metaElements}</Helmet>
}

export function prepareDataForAutocomplete(allCategories) {
    let allCategoriesJSON = []
    allCategories.forEach(categoryName => {
        allCategoriesJSON.push({categoryName: categoryName})
    })
    return allCategoriesJSON;
}

export function filterCurrentStoreDataFromAllStoreData(allStoreData, storeUrl) {
    return allStoreData.filter(function (store) {
        return store.url === storeUrl
    })[0]

}


// export function showToast(toastType, toastMessage) {
//     setToastStatus({
//         toastType: toastType,
//         toastMessage: toastMessage,
//         date: Date()
//     })
// }