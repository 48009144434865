import React, {lazy, Suspense, useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Login from "./Login/Login";
import axios from "axios";
import {serverUrl} from "./utils/config";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import loadingGif from './assets/loading.webp'
import Fade from "@material-ui/core/Fade";
import {AppContextProvider} from "./context/AppContext";
import {CartContextProvider} from "./context/CartContext";
import {AuthContextProvider} from "./context/AuthContext";

const StoreFront = lazy(() => import("./Store/StoreFront"));
const Cart = lazy(() => import('./Store/Cart'))
const HomePage = lazy(() => import("./Landing/HomePage"));
const CompanyLanding = lazy(() => import("./Company/CompanyLanding"));
const CompanyAdminPage = lazy(() => import("./Admin/CompanyAdminPage"));
const GodModeAlt = lazy(() => import("./Admin/GodModeAlt"));
const NoMatch = lazy(() => import("./utils/NoMatch"));
const Services = lazy(() => import("./Company/ServicesPage"));
const BecomeAMember = lazy(() => import("./Admin/BecomeAmember"));

function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [validRoutes, setValidRoutes] = useState(null)

    function formatValidRoutes(routesArray) {
        let validRoutes = ""
        routesArray.forEach(validRoute => {
            validRoutes += validRoute + "|"
        })
        return validRoutes;
    }

    async function fetchValidURLs() {
        try {
            let response = await axios({
                method: "GET",
                url: serverUrl + "/getValidUrls",
            })
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }

    useEffect(() => {
        const initializeAppData = async () => {
            setValidRoutes(formatValidRoutes(await fetchValidURLs()))
            setIsLoading(false)
        };
        initializeAppData();
    }, []);


    if (isLoading) {
        return (
            <div className="App">
                <Fade in={true} timeout={1000}>
                    <img src={loadingGif} style={{maxWidth: '50vw', paddingTop: '30vh'}} alt={""}/>
                </Fade>
            </div>
        )
    }

    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <AuthContextProvider>
                <AppContextProvider>
                    <CartContextProvider>
                        <div className="App">
                            <Router>
                                <Suspense
                                    fallback={<div><img src={loadingGif} style={{maxWidth: '50vw', paddingTop: '30vh'}}
                                                        alt={""}/></div>}>
                                    <Switch>
                                        <Route exact path={"/"}
                                               render={() => (<HomePage/>)}/>
                                        <Route exact path={"/admin"} component={GodModeAlt}/>
                                        <Route exact path={"/home"}
                                               render={() => (<HomePage/>)}/>
                                        <Route exact path={"/store"} component={StoreFront}/>
                                        <Route exact path={"/postaniclan"} component={BecomeAMember}/>
                                        <Route exact path={"/cart"} component={Cart}/>
                                        <Route exact path={"/:companyName(" + validRoutes + ')'}
                                               render={() => (<CompanyLanding/>)}/>
                                        <Route exact path="/:companyName/izlog"
                                               render={() => (<StoreFront/>)}/>
                                        <Route exact path="/:companyName/usluge"
                                               render={() => (<Services/>)}/>
                                        <Route exact path="/:companyName/admin"
                                               render={() => (<CompanyAdminPage/>)}/>
                                        <Route path={"/login"} component={Login}/>
                                        <Route render={() => (validRoutes === null ? null : <NoMatch/>)}/>
                                    </Switch>
                                </Suspense>
                            </Router>
                        </div>
                    </CartContextProvider>
                </AppContextProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
}

export default App;

const theme = createMuiTheme({
    typography: {
        fontSize: 15,
        fontFamily: 'Kalam',
        h1: {
            fontSize: '4em',
            // fontFamily: 'Cinzel',
        },
        h2: {
            fontSize: '3em',
            fontFamily: 'Cinzel',
        },
        h3: {
            fontSize: '1.5em',
            // fontFamily: 'Cinzel',

        },
        h4: {
            fontSize: '1em',
            // fontFamily: 'Cinzel',
        },
        h5: {
            fontSize: '0.5em',
            // fontFamily: 'Cinzel',
        },
    },
    palette: {
        "common": {
            "black": "rgba(0, 0, 0, 1)",
            "white": "rgba(254, 249, 249, 1)"
        },
        "background": {
            "paper": "#fff",
            "default": "#fafafa"
        },
        "primary": {
            "light": "rgba(81, 84, 98, 1)",
            "main": "#304C89",
            "dark": "#CDC392",
            "contrastText": "#fff"
        },
        "secondary": {
            "light": "#ff4081",
            "main": "rgba(32, 40, 206, 0.84)",
            "dark": "#c51162",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "serviceName": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
});


